import { useCallback, useEffect, useState } from 'react';
import { Form, Input, Modal, Select, TreeSelect } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import LocalCompanyService from '../../../services/local-company';
import { Region, Subcategory } from '../../../models';
import FilterService from '../../../services/filter';
import { SelectItem } from '../../../components/form';
import CountriesService from '../../../services/countries';
import { Error } from '../../../components/error/Error';

type Props = {
  open: boolean;
  handleCancel: () => void;
  companyData?: Record<string, any>;
  fetchData?: () => void;
};

const permissionValues = {
  sales_stat: {
    sales_statistics: false,
    trends: false,
    comparison: false,
  },
  share: {
    market_share: false,
    share_trends: false,
    share_comparison: false,
  },
  store_sales: {
    sales_by_stores: false,
  },
  analysis: {
    abc_analysis: false,
    out_of_stock: false,
    price_elasticity: false,
    product_comparison: false,
    coexistence: false,
    coexistence_trends: false,
    vpo_segmentation: false,
    vpo_trend: false,
    buying_behavior: false,
  },
  manage: {
    users: false,
    roles: false,
    journal: false,
  },
};

const moduleCategories = {
  sales_stat: 'Sales Report',
  share: 'Share',
  store_sales: 'Store Sales',
  analysis: 'Analysis',
  manage: 'Management',
  others: 'Others',
};

const createOptionsFromModules = (modules, permissionValues) => {
  const groupedOptions = Object.entries(permissionValues).map(([category, items]) => {
    const options = Object.keys(items)
      .map((key) => {
        const module = modules.find((m) => m.name === key);
        return module ? { label: <span>{key}</span>, value: module.id } : null;
      })
      .filter(Boolean);
    return {
      label: <span>{moduleCategories[category]}</span>,
      title: moduleCategories[category],
      options,
    };
  });

  const categorizedModuleNames = Object.values(permissionValues).flatMap(Object.keys);
  const otherModules = modules
    .filter((module) => !categorizedModuleNames.includes(module.name))
    .map((module) => ({
      label: <span>{module.name}</span>,
      value: module.id,
    }));

  if (otherModules.length > 0) {
    groupedOptions.push({
      label: <span>{moduleCategories.others}</span>,
      title: moduleCategories.others,
      options: otherModules,
    });
  }

  return [{ label: <span style={{ fontWeight: 'bold' }}>All</span>, value: 'selectAll' }, ...groupedOptions];
};

export const LocalCompanyDialog = ({ open, handleCancel, companyData, fetchData }: Props) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  // const [loading, setLoading] = useState<boolean>(false);
  // const [regions, setRegions] = useState<Region[]>([]);
  // const [category, setCategory] = useState<any[]>([]);
  // const [subcategory, setSubcategory] = useState<Subcategory[]>([]);
  const [error, setError] = useState<string>(null);
  // const [country, setCountry] = useState([]);
  // const [options, setOptions] = useState([]);
  // const watchIsActive = Form.useWatch('is_active', form);

  // useEffect(() => {
  //   if (companyData && open) {
  //     form.setFieldsValue({
  //       ...companyData,
  //       category_ids: companyData?.categories?.map((item: any) => item.id),
  //       subcategory_ids: companyData?.subcategories?.map((item: any) => item.id),
  //       country_id: companyData?.country?.id,
  //       city_ids: companyData?.cities?.map((item: any) => item.id),
  //       module_ids: companyData?.modules?.map((item: any) => item.id),
  //     });
  //   }
  //   return () => {
  //     form.resetFields();
  //   };
  // }, [companyData, open]);

  // const fetchDefaultFilters = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const [citiesRes, subcategoryRes, categoryRes, countryRes] = await Promise.allSettled([
  //       FilterService.getCities(),
  //       FilterService.getNewOptions('subcategory'),
  //       FilterService.getNewOptions('category'),
  //       CountriesService.getCountries(),
  //     ]);

  //     const cities = citiesRes.status === 'fulfilled' ? citiesRes?.value?.data?.results : [];
  //     const subcategories =
  //       subcategoryRes.status === 'fulfilled'
  //         ? subcategoryRes.value.data.map((item: any) => ({ label: item['name_val'], value: item['id'] }))
  //         : [];
  //     const categories =
  //       categoryRes.status === 'fulfilled'
  //         ? categoryRes.value.data.map((item: any) => ({ label: item['name_val'], value: item['id'] }))
  //         : [];
  //     const countries =
  //       countryRes.status === 'fulfilled'
  //         ? countryRes.value?.results?.map((item: any) => ({ label: item['name_val'], value: item['id'] }))
  //         : [];

  //     setRegions(cities);
  //     setSubcategory(subcategories);
  //     setCategory(categories);
  //     setCountry(countries);
  //   } catch (error) {
  //     setRegions([]);
  //     setSubcategory([]);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (open) {
  //     fetchDefaultFilters();
  //   }
  //   return () => {
  //     setSubcategory([]);
  //     setRegions([]);
  //   };
  // }, [fetchDefaultFilters, open]);

  // useEffect(() => {
  //   FilterService.getModules().then((res) => {
  //     const modules = res;
  //     const newOptions = createOptionsFromModules(modules, permissionValues);
  //     setOptions(newOptions);
  //   });
  // }, []);

  const handleCreate = (body: any) => {
    setConfirmLoading(true);
    LocalCompanyService.createLocalCompany(body)
      .then((res) => {
        fetchData();
        handleCancel();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          const errorMessage = Object.values(err?.response?.data || {})
            .flat()
            .join(' ');
          setError(errorMessage || 'Validation error');
        } else {
          setError('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const handleEdit = (id: string | number, body: any) => {
    LocalCompanyService.editLocalCompany(id, body)
      .then((res) => {
        fetchData();
        handleCancel();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          const errorMessage = Object.values(err?.response?.data || {})
            .flat()
            .join(' ');
          setError(errorMessage || 'Validation error');
        } else {
          setError('Something went wrong');
        }
      })
      .finally(() => setConfirmLoading(false));
  };

  const onFinish = (values: Record<string, string>) => {
    if (companyData?.id) {
      handleEdit(companyData?.id, values);
    } else {
      handleCreate(values);
    }
  };

  const [selectedValues, setSelectedValues] = useState([]);

  // const handleChange = (values) => {
  //   const allModuleIds = options.flatMap((group) => group.options?.map((opt) => opt.value) || []);

  //   if (values.includes('selectAll')) {
  //     if (selectedValues.length === allModuleIds.length) {
  //       setSelectedValues([]);
  //       form.setFieldsValue({ module_ids: [] });
  //     } else {
  //       setSelectedValues(allModuleIds);
  //       form.setFieldsValue({ module_ids: allModuleIds });
  //     }
  //   } else {
  //     setSelectedValues(values);
  //     form.setFieldsValue({ module_ids: values });
  //   }
  // };

  return (
    <Modal
      title="Create company"
      open={open}
      onOk={form.submit}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} initialValues={companyData || null}>
        {/* <Form.Item name="is_active" label="Is active" valuePropName="checked" style={{ marginTop: '16px' }}>
          <Checkbox />
        </Form.Item> */}
        <Form.Item name="name_val" label="Company name" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
          <Input />
        </Form.Item>
      </Form>
      {error && <Error textError={error} />}
    </Modal>
  );
};
