import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Drawer, Form, Input, Modal, Row, Select, Spin, Typography, notification } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import PermissionsService from '../../../services/permissions';
import { PlusOutlined } from '@ant-design/icons';
import { CreateRole } from '../CreateRole/CreateRole';
import styles from './styles.module.scss';
import { SelectItem } from '../../../components/form';
import LocalCompanyService from '../../../services/local-company';

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};

type Props = {
  id?: string;
  onCloseEditDrawer?: () => void;
  close?: () => void;
  isUserEditOpen?: boolean;
  getData?: () => void;
};

export const CreateUserNew = ({ id, onCloseEditDrawer, close, isUserEditOpen, getData }: Props) => {
  const { userId } = useParams<{ userId: string }>();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState<{}>(null);
  const [roles, setRoles] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<string | number>(null);
  const [form] = Form.useForm();
  const [localCompanyOptions, setLocalCompanyOptions] = useState([]);
  const userCompanyId = JSON.parse(localStorage.getItem('user'))?.company?.id;
  const companyWatch = Form.useWatch('company', form);

  const onNavigateTable = () => {
    onCloseEditDrawer();
    form.resetFields();
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.warning({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values: any) => {
    if (userId || id) {
      PermissionsService.editUserById(userId || id, { ...values })
        .then((res) => {
          onNavigateTable();
        })
        .catch((err) => {
          openNotification(err.response?.data?.email[0]);
        });
    } else {
      PermissionsService.createUser({ ...values })
        .then((res) => {
          onNavigateTable();
        })
        .catch((err) => {
          openNotification(err.response?.data?.email[0]);
        });
    }
  };

  const getLocalCompanies = useCallback(() => {
    LocalCompanyService.getLocalCompanies({ all: true }).then((res) => {
      const localCompany = res?.map((item) => ({ label: item.name_val, value: item.id })) || [];
      setLocalCompanyOptions(localCompany);
    });
  }, []);

  useEffect(() => {
    if (userId || id) {
      PermissionsService.getUserById(userId || id).then((res) => {
        form.setFieldsValue({
          ...res.data,
          role: res?.data?.role?.id,
          company: res?.data?.company?.id,
        });
      });
    }
    return () => {
      setUserInfo(null);
    };
  }, [userId, id, form]);

  useEffect(() => {
    PermissionsService.getRoles({ company: companyWatch, limit: 1000 })
      .then((res) => {
        setRoles(res.data.results.map((item: any) => ({ label: item['name'], value: item['id'] })));
      })
      .catch((err) => setRoles([]))
      .finally(() => setLoading(false));
  }, [companyWatch]);

  useEffect(() => {
    getLocalCompanies();
  }, [getLocalCompanies]);

  return (
    <Drawer
      title={`${id ? 'Edit' : 'Create'}  user`}
      width="400px"
      placement="right"
      onClose={onNavigateTable}
      open={isUserEditOpen}
    >
      <Form
        form={form}
        onFinish={onFinish}
        className={styles.form}
        {...formItemLayout}
        layout="vertical"
        initialValues={userInfo || null}
      >
        {/* <Text type="secondary">Personal information</Text> */}
        <div>
          <div>
            <Typography style={{ margin: 0, padding: 0 }}>Name</Typography>
            <Form.Item name="first_name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </div>

          <div>
            <Typography style={{ margin: 0, padding: 0 }}>Last name</Typography>
            <Form.Item name="last_name">
              <Input />
            </Form.Item>
          </div>

          <div>
            <Typography style={{ margin: 0, padding: 0 }}>Username</Typography>
            <Form.Item name="username" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </div>

          <div>
            <Typography style={{ margin: 0, padding: 0 }}>Phone</Typography>
            <Form.Item name="phone" rules={[{ required: true }]}>
              <Input maxLength={11} />
            </Form.Item>
          </div>

          <div>
            <Typography style={{ margin: 0, padding: 0 }}>Email</Typography>
            <Form.Item name="email" rules={[{ required: true }]}>
              <Input type="email" />
            </Form.Item>
          </div>

          {!(userId || id) && (
            <div>
              <Typography style={{ margin: 0, padding: 0 }}>Password</Typography>
              <Form.Item name="password" rules={[{ required: true }]}>
                <Input.Password type="password" autoComplete={'nope'} />
              </Form.Item>
            </div>
          )}

          <div>
            <Form.Item name="company" label="Company ">
              <SelectItem
                options={localCompanyOptions}
                name="company"
                allowClear
                placeholder="Choose a company"
                disabledMode
              />
            </Form.Item>
          </div>
          <Row>
            <Col span={16}>
              <Typography style={{ margin: 0, padding: 0 }}>Role</Typography>
              <Form.Item name="role" rules={[{ required: true }]}>
                <Select
                  options={roles || []}
                  allowClear={true}
                  placeholder="Choose a role"
                  notFoundContent={loading ? <Spin size="small" /> : null}
                  // onChange={(val, option) => setCompanyId(option.company?.id)}
                />
              </Form.Item>
            </Col>
            <Col span={1}>
              <Button
                size="middle"
                htmlType="button"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={showModal}
                style={{ marginTop: '20px', marginLeft: '-70px' }}
              />
            </Col>
          </Row>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 18 }}>
          <Button type="default" htmlType="button" onClick={onNavigateTable}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
      <Modal title="Create new role" open={isModalOpen} footer={null} onCancel={handleCancel}>
        <CreateRole isModal link={location.pathname} close={handleCancel} />
      </Modal>
    </Drawer>
  );
};
