import { useEffect, useState } from 'react';
import { Button, Card, Drawer, Skeleton, Space } from 'antd';
import { AppstoreAddOutlined, DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';

import { CategoriesDialog } from './components/Dialog';
import { SidePanel } from './components/SidePanel';
import { ConfirmationModal } from '../../components/confirmationModal/ConfirmationModal';
import CategoriesService from '../../services/categories';
import styles from './styles.module.scss';

const { Meta } = Card;

export const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState<boolean>(false);
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Record<string, string>>(null);
  const [error, setError] = useState<string>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
    setDrawerTitle('');
    setSelectedCategory(null);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setConfirmationModal(false);
    setSelectedCategory(null);
  };

  useEffect(() => {
    fetchCategories();
    return () => {
      setCategories([]);
    };
  }, []);

  const fetchCategories = () => {
    setLoading(true);
    CategoriesService.getCategories({ limit: 20 })
      .then((res) => setCategories(res?.results))
      .finally(() => setLoading(false));
  };

  const handleDelete = (id: string | number) => {
    CategoriesService.deleteCategory(id)
      .then((res) => {
        fetchCategories();
        handleCloseModal();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          setError(err?.response?.data?.name_val?.[0]);
        } else {
          setError('Something went wrong');
        }
      });
  };

  return (
    <div className={styles.layout_content}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
        <Button
          type="default"
          onClick={() => {
            showModal();
            setSelectedCategory(null);
          }}
        >
          + Category
        </Button>
      </div>
      <Space direction="horizontal" wrap>
        {loading ? (
          <>
            <Skeleton.Node active={loading}>
              <LoadingOutlined style={{ fontSize: 50, color: '#bfbfbf' }} />
            </Skeleton.Node>
            <Skeleton.Node active={loading}>
              <LoadingOutlined style={{ fontSize: 50, color: '#bfbfbf' }} />
            </Skeleton.Node>
            <Skeleton.Node active={loading}>
              <LoadingOutlined style={{ fontSize: 50, color: '#bfbfbf' }} />
            </Skeleton.Node>
          </>
        ) : (
          categories?.map((item) => (
            <Card
              loading={loading}
              key={item?.id}
              style={{ width: 250 }}
              actions={[
                <EditOutlined
                  key="edit"
                  onClick={() => {
                    showModal();
                    setSelectedCategory(item);
                  }}
                />,
                <DeleteOutlined
                  key="delete"
                  onClick={() => {
                    setConfirmationModal(true);
                    setSelectedCategory(item);
                  }}
                />,
              ]}
            >
              <Meta
                title={
                  <Button
                    type="link"
                    onClick={() => {
                      showDrawer();
                      setDrawerTitle(item?.name_val);
                      setSelectedCategory(item);
                    }}
                  >
                    {item?.name_val}
                  </Button>
                }
              />
            </Card>
          ))
        )}
      </Space>

      <Drawer title={drawerTitle} placement="right" closable={true} onClose={onClose} open={openDrawer}>
        <SidePanel categoryId={selectedCategory?.id} />
      </Drawer>

      <CategoriesDialog
        open={open}
        formData={selectedCategory}
        handleCancel={handleCancel}
        fetchData={fetchCategories}
      />
      <ConfirmationModal
        open={openConfirmationModal}
        close={handleCloseModal}
        title={``}
        textError={error}
        description={`Are you sure you want to delete ${selectedCategory?.name_val} ?`}
        onSubmit={() => handleDelete(selectedCategory?.id)}
      />
    </div>
  );
};
