import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Drawer } from 'antd';

import { CustomTable } from './components/Table';
import { UserFilter } from '../../components/filter/UserFilter';
import { CreateUserNew } from './CreateUser/CreateUserNew';
import styles from './styles.module.scss';

export const PermissionsPage = () => {
  const navigate = useNavigate();
  const { type } = useParams<{ type: string }>();
  const [isUserEditOpen, setIsUserEditOpen] = useState<boolean>(false);

  const onNavigateToCreateUser = () => {
    setIsUserEditOpen(true);
  };

  const onNavigateToCreateRole = () => {
    navigate('/permissions/roles/create');
  };

  const closeUserEditDrawer = () => {
    setIsUserEditOpen(false);
  };

  return (
    <div className={styles.company_layout_content}>
      <div className={styles.top_bar}>
        {/* <div className={styles.title}></div> */}
        <div style={{ marginLeft: '15px' }}>
          <UserFilter pageType={type} />
        </div>
      </div>

      <div style={{ marginTop: '24px' }}>
        <CustomTable pageName={type} />
      </div>
    </div>
  );
};
