import { useCallback, useEffect, useMemo, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import qs from 'qs';

import { AuthStatus } from './services/auth';
import { useAuthService } from './context/authContext';
import { Profile } from './models/authModel';
import { Loader } from './components/loader/Loader';
import { Login } from './pages/login/login';
// import { ForgetPassword } from './pages/login/ForgetPassword';
// import { RestorePassword } from './pages/login/RestorePassword';
// import { PublicRoute } from './router/PublicRoute';
import { PrivateRoute } from './router/PrivateRoute';
// import { AppRouter } from './router/AppRouter';
import './App.css';
import { ProtectedLayout } from './components/protectedLayout/protectedLayout';
import { CountriesPage } from './pages/countries/CountriesPage';
import { CompaniesPage } from './pages/companies/CompaniesPage';
import { BrandsPage } from './pages/brands/BrandsPage';
import { CategoriesPage } from './pages/categories/CategoriesPage';
import { ProductsPage } from './pages/products/ProductsPage';
import { PermissionsPage } from './pages/permissions/PermissionsPage';
import { CreateUser } from './pages/permissions/CreateUser/CreateUser';
import { CreateRole } from './pages/permissions/CreateRole/CreateRole';
import { LocalCompanyPage } from './pages/local-company/LocalCompanyPage';

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<Profile>();
  const authService = useAuthService();
  // const routeProps = useMemo(() => ({ profile, loading }), [profile, loading]);

  const valuesFromUrl = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const tokenFromUrl = valuesFromUrl?.token;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        refetchOnWindowFocus: false,
      },
    },
  });

  const logout = useCallback(() => {
    if (location.pathname !== '/restore') {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user');
      setProfile(null);
      navigate('/login');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (tokenFromUrl) {
      localStorage.setItem('token', tokenFromUrl);
    } else if (!localStorage.getItem('token')) {
      logout();
    }
  }, [tokenFromUrl, logout]);

  useEffect(() => {
    authService.loginStatus.on(AuthStatus.UNAUTHORIZED, logout);

    return () => {
      authService.loginStatus.off(AuthStatus.UNAUTHORIZED, logout);
    };
  }, [authService, logout]);

  useEffect(() => {
    setLoading(true);
    authService
      .getProfile()
      .then((profile) => {
        if (typeof profile === 'string') {
          throw Error('profile must be a object');
        } else {
          setProfile(profile);
          localStorage.setItem('user', JSON.stringify(profile));
        }
      })
      .catch((err) => {
        localStorage.removeItem('user');
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setProfile(null);
    };
  }, [authService]);

  const appRoutes = (
    <ProtectedLayout user={profile} logout={logout}>
      <Routes>
        <Route path="/countries" element={<CountriesPage />} />
        <Route path="/companies" element={<CompaniesPage />} />
        <Route path="/local-companies" element={<LocalCompanyPage />} />
        <Route path="/brands" element={<BrandsPage />} />
        <Route path="/categories" element={<CategoriesPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/permissions/:type" element={<PermissionsPage />} />
        <Route path="/permissions/users/create" element={<CreateUser />} />
        <Route path="/permissions/users/:userId/edit" element={<CreateUser />} />
        <Route path="/permissions/roles/create" element={<CreateRole />} />
        <Route path="/permissions/roles/:roleId/edit" element={<CreateRole />} />
      </Routes>
    </ProtectedLayout>
  );

  return loading ? (
    <Loader />
  ) : (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/login" element={profile ? <Navigate to={'/countries'} replace /> : <Login />} />
        <Route path="/not-found" element={null} />
        <Route path="*" element={<PrivateRoute profile={profile}>{appRoutes}</PrivateRoute>} />
      </Routes>
    </QueryClientProvider>
  );
}
