import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Collapse, Drawer, Form, Input, notification, TreeSelect, Typography } from 'antd';

import FilterService from '../../../services/filter';
import PermissionsService from '../../../services/permissions';
import { SalesFormItems } from '../components/SalesFormItems';

import { ShareByCitiesFormItemsNew } from '../components/ShareByCitiesFormItemsNew';
import { StoreFormItemsNew } from '../components/StoreFormItemsNew';
import { AnalysisFormItemsNew } from '../components/AnalysisFormItemsNew';
import { ManageFormItemsNew } from '../components/ManageFormItemsNew';
import styles from './styles.module.scss';
import { SelectItem } from '../../../components/form';
import LocalCompanyService from '../../../services/local-company';

const { Panel } = Collapse;

type Props = {
  isModal?: boolean;
  link?: string;
  close?: () => void;
  id?: string;
  onCloseEditDrawer?: () => void;
  isUserEditOpen?: boolean;
  getData?: () => void;
};

export const CreateRoleNew = ({ isModal, link, close, id, onCloseEditDrawer, isUserEditOpen, getData }: Props) => {
  const { roleId } = useParams<{ roleId: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [regions, setRegions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const userCompanyId = JSON.parse(localStorage.getItem('user'))?.company?.id;
  const [storeValue, setStoreValue] = useState<any>();
  const { setFieldValue } = form;
  const [localCompanyOptions, setLocalCompanyOptions] = useState([]);

  const onNavigateTable = () => {
    if (isModal) {
      navigate(link);
      close();
    } else {
      // history.push('/permissions/roles');
      onCloseEditDrawer();
      form.resetFields();
    }
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.success({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  const onFinish = (values: any) => {
    FilterService.getModules().then((res: any) => {
      const moduleMap = {};
      res?.forEach((module) => {
        moduleMap[module.name] = module.id;
      });

      const modules = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key !== 'name' && key !== 'regions' && key !== 'company') {
          if (key === 'sales_by_stores' && value) {
            modules.push(moduleMap['sales_by_stores'], moduleMap['store_statistics']);
          } else if (value) {
            modules.push(moduleMap[key]);
          }
        }
      });

      if (roleId || id) {
        PermissionsService.editRoleById(roleId || id, {
          regions: values.regions,
          name: values.name,
          permissions: modules,
          modules: modules,
          company: values.company,
        }).then((res) => {
          getData();
          openNotification('Role changed!');
          onNavigateTable();
        });
      } else {
        PermissionsService.createRole({
          regions: values?.regions,
          name: values?.name,
          permissions: modules,
          modules: modules,
          company: values.company,
        }).then((res) => {
          openNotification('Role created!');
          getData();
          if (isModal) {
            navigate(link);
            close();
          } else {
            navigate('/permissions/roles');
            onNavigateTable();
          }
        });
      }
    });
  };

  const getRegions = useCallback(() => {
    setLoading(true);
    FilterService.getCities(true)
      .then((res) => {
        setRegions(res.data.results);
      })
      .catch((err) => setRegions([]))
      .finally(() => setLoading(false));
  }, []);

  const getLocalCompanies = useCallback(() => {
    LocalCompanyService.getLocalCompanies({ all: true }).then((res) => {
      const localCompany = res?.map((item) => ({ label: item.name_val, value: item.id })) || [];
      setLocalCompanyOptions(localCompany);
    });
  }, []);

  useEffect(() => {
    getRegions();
    getLocalCompanies();
    return () => {
      setRegions([]);
      setLocalCompanyOptions([]);
    };
  }, [getLocalCompanies, getRegions]);

  useEffect(() => {
    if (roleId || id) {
      PermissionsService.getRoleById(roleId || id).then((res) => {
        const permissions = res.data.permissions
          ?.map((item) => {
            return { [item]: true };
          })
          .reduce((obj: any, item: any) => Object.assign(obj, item), {});
        form.setFieldsValue({
          ...res.data,
          ...permissions,
          regions: res?.data?.regions.map(String),
          company: res?.data?.company?.id,
        });
        setStoreValue(form.getFieldValue('sales_by_stores') || false);
      });
    }
  }, [roleId, id, form]);

  return (
    <Drawer
      title={`${id ? 'Edit' : 'Create'}  role`}
      width="400px"
      placement="right"
      onClose={onNavigateTable}
      open={isUserEditOpen}
    >
      <Form form={form} onFinish={onFinish} className={styles.form}>
        <div>
          <div>
            <Typography style={{ margin: 0, padding: 0 }}>Role name</Typography>
            <Form.Item name="name" rules={[{ required: true }]} labelAlign="left">
              <Input />
            </Form.Item>
          </div>
          <div>
            <Typography>Company</Typography>
            <Form.Item name="company" rules={[{ required: true }]} style={{ marginTop: '16px' }} labelCol={{ span: 6 }}>
              <SelectItem
                options={localCompanyOptions}
                name="company"
                allowClear
                placeholder="Choose a company"
                disabledMode
              />
            </Form.Item>
          </div>
          <div>
            <Typography>Region</Typography>
            <Form.Item name="regions" labelAlign="left" rules={[{ required: true }]}>
              <TreeSelect
                treeData={regions}
                treeCheckable={true}
                labelInValue={false}
                placeholder="Please select"
                maxTagCount="responsive"
                loading={loading}
                allowClear
              />
            </Form.Item>
          </div>

          {/* <Tabs className="card-tabs">
            <TabPane tab="Sales" key="sales" forceRender>
              <SalesFormItems isModal={isModal} />
            </TabPane>
            <TabPane tab="Share by cities" key="share" forceRender>
              <ShareByCitiesFormItems isModal={isModal} />
            </TabPane>
            <TabPane tab="Stores" key="stores" forceRender>
              <StoreFormItems isModal={isModal} />
            </TabPane>
            <TabPane tab="Analysis" key="analysis" forceRender>
              <AnalysisFormItems isModal={isModal} permission={userCompanyId} />
            </TabPane>
            <TabPane tab="Manage" key="manage" forceRender>
              <ManageFormItems isModal={isModal} />
            </TabPane>
          </Tabs> */}
          <Collapse className="card-tabs">
            <Panel header="Sales Report" key="1" forceRender>
              <SalesFormItems isModal={isModal} />
            </Panel>
            <Panel header="Share by cities" key="2" forceRender>
              <ShareByCitiesFormItemsNew isModal={isModal} />
            </Panel>
            <Panel header="Stores" key="3" forceRender>
              <StoreFormItemsNew isModal={isModal} setFieldValue={setFieldValue} value={storeValue} />
            </Panel>
            <Panel header="Analysis" key="4" forceRender>
              <AnalysisFormItemsNew isModal={isModal} permission={userCompanyId} />
            </Panel>
            <Panel header="Manage" key="5" forceRender>
              <ManageFormItemsNew isModal={isModal} />
            </Panel>
          </Collapse>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 18 }}>
          <Button type="default" htmlType="button" onClick={onNavigateTable}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};
