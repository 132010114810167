import { ColumnsType } from 'antd/lib/table';
import { Button, Space } from 'antd';

interface DataType {
  key: React.Key;
  name: string;
}

export const useBrandsColumns = ({
  setBrand,
  showModal,
  setSelectedBrand,
  setConfirmationModal,
}: any): ColumnsType<DataType> => {
  return [
    {
      title: 'Brand',
      dataIndex: 'name_val',
      key: 'name_val',
    },
    {
      title: 'Categories',
      key: 'categories',
      render: (_, record: any) => (
        <Space size="middle">{record?.categories?.map((item) => item.name_val).join(',')}</Space>
      ),
    },
    {
      title: 'Subcategories',
      key: 'subcategories',
      render: (_, record: any) => (
        <Space size="middle">{record?.subcategories?.map((item) => item.name_val).join(',')}</Space>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record: any) => (
        <Space size="middle">
          <Button
            onClick={() => {
              setBrand(record);
              showModal();
            }}
          >
            Edit
          </Button>
          {/* <Button
            onClick={() => {
              setSelectedBrand(record);
              setConfirmationModal(true);
            }}
          >
            Delete
          </Button> */}
        </Space>
      ),
    },
  ];
};
