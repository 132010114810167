import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select, Spin, Typography } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import PermissionsService from '../../../services/permissions';
import { PlusOutlined } from '@ant-design/icons';
import { CreateRole } from '../CreateRole/CreateRole';
import LocalCompanyService from '../../../services/local-company';
import { SelectItem } from '../../../components/form';
import { LocalCompany } from '../../../models';
import styles from './styles.module.scss';
import { fetchLocalCompany } from '../../../utils/fetchSearchValue';

const { Text } = Typography;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
export const CreateUser = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState<{}>(null);
  const [roles, setRoles] = useState<any[]>([]);
  const [companies, setCompanies] = useState<LocalCompany[]>([]);
  const [companyString, setCompanyString] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [form] = Form.useForm();

  // const userCompanyId = JSON.parse(localStorage.getItem('user'))?.company?.id;

  const onNavigateTable = () => {
    navigate('/permissions/users');
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSearchLocalCompany = (value) => {
    fetchLocalCompany(value, setCompanies);
  };

  const onFinish = (values: any) => {
    if (userId) {
      PermissionsService.editUserById(userId, { ...values }).then((res) => navigate('/permissions/users'));
    } else {
      PermissionsService.createUser(values).then((res) => navigate('/permissions/users'));
    }
  };

  useEffect(() => {
    if (userId) {
      PermissionsService.getUserById(userId).then((res) => {
        form.setFieldsValue({
          ...res.data,
          role: res?.data?.role?.id,
          company: res?.data?.company?.id,
        });
      });
    }
    return () => {
      setUserInfo(null);
    };
  }, [userId]);

  const getLocalCompanies = useCallback(() => {
    LocalCompanyService.getLocalCompanies({ limit: 20 })
      .then((res) => {
        setCompanies(res.results.map((item: any) => ({ label: item['name_val'], value: item['id'] })));
      })
      .catch((err) => setCompanies([]));
  }, []);

  useEffect(() => {
    setLoading(true);
    PermissionsService.getRoles({ limit: 1000, company: companyString })
      .then((res) => {
        setRoles(res.data.results.map((item: any) => ({ label: item['name'], value: item['id'] })));
      })
      .catch((err) => setRoles([]))
      .finally(() => setLoading(false));
  }, [companyString]);

  useEffect(() => {
    getLocalCompanies();
  }, [getLocalCompanies]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <Text>{userId ? 'Edit' : 'Create'} user</Text>
      </div>
      <Form
        form={form}
        onFinish={onFinish}
        className={styles.form}
        {...formItemLayout}
        // initialValues={userInfo || null}
      >
        <div className={styles.actions}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button type="default" htmlType="button" onClick={onNavigateTable} style={{ marginLeft: '16px' }}>
            Cancel
          </Button>
        </div>
        <Row gutter={[16, 8]} className={styles.formData}>
          <Col span={12}>
            <Text type="secondary">Personal information</Text>
            <Form.Item name="first_name" label="Name" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
              <Input />
            </Form.Item>
            <Form.Item name="last_name" label="Last name">
              <Input />
            </Form.Item>
            <Form.Item name="username" label="Username" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
              <Input maxLength={11} />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ required: true }]}>
              <Input type="email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text type="secondary">Additional information</Text>
            {!userId && (
              <Form.Item name="password" label="Password" rules={[{ required: true }]} style={{ marginTop: '16px' }}>
                <Input type="password" />
              </Form.Item>
            )}

            <Row>
              <Col span={16}>
                <Form.Item
                  name="company"
                  label="Company"
                  rules={[{ required: true }]}
                  style={{ marginTop: '16px' }}
                  labelCol={{ span: 6 }}
                >
                  <SelectItem
                    options={companies}
                    name="company"
                    allowClear
                    placeholder="Choose a company"
                    disabledMode
                    onSearch={handleSearchLocalCompany}
                    onClear={getLocalCompanies}
                    onChange={(value) => setCompanyString(value)}
                    style={{ width: '294px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="role"
                  label="Role"
                  rules={[{ required: true }]}
                  style={{ marginTop: '16px' }}
                  labelCol={{ span: 6 }}
                >
                  <Select
                    options={roles || []}
                    allowClear={true}
                    loading={loading}
                    disabled={loading}
                    placeholder="Choose a role"
                    notFoundContent={loading ? <Spin size="small" /> : null}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Button
                  size="middle"
                  htmlType="button"
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={showModal}
                  style={{ marginTop: '16px' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <Modal title="Create new role" open={isModalOpen} footer={null} onCancel={handleCancel}>
        <CreateRole isModal link={location.pathname} close={handleCancel} />
      </Modal>
    </div>
  );
};
