import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Input, notification, Select, Tabs, TreeSelect, Typography } from 'antd';

import FilterService from '../../../services/filter';
import PermissionsService from '../../../services/permissions';
import { SalesFormItems } from '../components/SalesFormItems';
import { ShareByCitiesFormItems } from '../components/ShareByCitiesFormItems';
import { AnalysisFormItems } from '../components/AnalysisFormItems';
import { ManageFormItems } from '../components/ManageFormItems';
import LocalCompanyService from '../../../services/local-company';
import { SelectItem } from '../../../components/form';
import { fetchLocalCompany } from '../../../utils/fetchSearchValue';
import styles from './styles.module.scss';
import { StoreFormItems } from '../components/StoreFormItems';

const { Text } = Typography;
const { TabPane } = Tabs;

type Props = {
  isModal?: boolean;
  link?: string;
  close?: () => void;
};

export const CreateRole = ({ isModal, link, close }: Props) => {
  const { roleId } = useParams<{ roleId: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [regions, setRegions] = useState<any[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const userCompanyId = JSON.parse(localStorage.getItem('user'))?.company?.id;

  const onNavigateTable = () => {
    if (isModal) {
      navigate(link);
      close();
    } else {
      navigate('/permissions/roles');
    }
  };

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.success({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  const onFinish = (values: any) => {
    const permissions: any = [];
    Object.entries(values).map(([key, value]) => {
      if (key !== 'name' && key !== 'regions' && key !== 'company') {
        if (key === 'users' || key === 'roles') {
          Object.entries(value).map(([objKey, objVal]) => {
            if (objVal) {
              permissions.push({
                module: key,
                action: objKey,
              });
            }
          });
        } else if (value) {
          permissions.push({
            module: key,
            action: 'can_read',
          });
        }
      }
      return permissions;
    });

    if (roleId) {
      PermissionsService.editRoleById(roleId, {
        regions: values.regions,
        name: values.name,
        company: values?.company,
        permissions: permissions,
      }).then((res) => {
        openNotification('Role changed!');
        navigate('/permissions/roles');
      });
    } else {
      PermissionsService.createRole({
        company: values?.company,
        regions: values?.regions,
        name: values?.name,
        permissions: permissions,
      }).then((res) => {
        openNotification('Role created!');
        if (isModal) {
          navigate(link);
          close();
        } else {
          navigate('/permissions/roles');
        }
      });
    }
  };

  const handleSearchLocalCompany = (value) => {
    fetchLocalCompany(value, setCompanies);
  };

  const getLocalCompanies = useCallback(() => {
    LocalCompanyService.getLocalCompanies({ limit: 50 })
      .then((res) => {
        setCompanies(res.results.map((item: any) => ({ label: item['name_val'], value: item['id'] })));
      })
      .catch((err) => setCompanies([]));
  }, []);

  const getRegions = useCallback(() => {
    setLoading(true);
    FilterService.getCities(true)
      .then((res) => {
        setRegions(res.data.results);
      })
      .catch((err) => setRegions([]))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getRegions();
    return () => {
      setRegions([]);
    };
  }, [getRegions]);

  useEffect(() => {
    getLocalCompanies();
    return () => {
      setCompanies([]);
    };
  }, [getLocalCompanies]);

  useEffect(() => {
    if (roleId) {
      PermissionsService.getRoleById(roleId).then((res) => {
        const permissions = Object.keys(res.data.permissions)
          .map((item) => {
            if (item === 'roles' || item === 'users') {
              const arr = res.data.permissions[item]
                ?.map((el: any) => {
                  return {
                    [el]: true,
                  };
                })
                .reduce((obj: any, item: any) => Object.assign(obj, item), {});
              return {
                [item]: arr,
              };
            } else {
              return { [item]: true };
            }
          })
          .reduce((obj: any, item: any) => Object.assign(obj, item), {});

        form.setFieldsValue({
          ...res.data,
          ...permissions,
          regions: res?.data?.regions.map(String),
          company: res?.data?.company?.id,
        });
      });
    }
  }, [roleId]);

  return (
    <div className={styles.wrapper}>
      {!isModal && (
        <div className={styles.title}>
          <Text>{roleId ? 'Edit' : 'Create'} role</Text>
        </div>
      )}

      <Form
        form={form}
        onFinish={onFinish}
        className={styles.form}
        labelCol={{ span: isModal ? 6 : 3 }}
        wrapperCol={{ span: isModal ? 12 : 8 }}
      >
        <div className={styles.actions}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button type="default" htmlType="button" onClick={onNavigateTable} style={{ marginLeft: '16px' }}>
            Cancel
          </Button>
        </div>

        <div className={styles.formData}>
          <Form.Item
            name="name"
            label="Role name"
            rules={[{ required: true }]}
            style={{ marginTop: '16px' }}
            labelAlign="left"
          >
            <Input />
          </Form.Item>
          <Form.Item name="company" label="Company" labelAlign="left" rules={[{ required: true }]}>
            <SelectItem
              options={companies}
              name="company"
              allowClear
              placeholder="Select a company"
              disabledMode
              onClear={getLocalCompanies}
              onSearch={handleSearchLocalCompany}
            />
          </Form.Item>
          <Form.Item name="regions" label="Region" labelAlign="left" rules={[{ required: true }]}>
            <TreeSelect
              treeData={regions}
              treeCheckable={true}
              labelInValue={false}
              placeholder="Please select"
              maxTagCount="responsive"
              loading={loading}
              allowClear
            />
          </Form.Item>
          <Tabs className="card-tabs">
            <TabPane tab="Sales" key="sales" forceRender>
              <SalesFormItems isModal={isModal} />
            </TabPane>
            <TabPane tab="Share by cities" key="share" forceRender>
              <ShareByCitiesFormItems isModal={isModal} />
            </TabPane>
            <TabPane tab="Stores" key="stores" forceRender>
              <StoreFormItems isModal={isModal} />
            </TabPane>
            <TabPane tab="Analysis" key="analysis" forceRender>
              <AnalysisFormItems isModal={isModal} permission={userCompanyId} />
            </TabPane>
            <TabPane tab="Manage" key="manage" forceRender>
              <ManageFormItems isModal={isModal} />
            </TabPane>
          </Tabs>
        </div>
      </Form>
    </div>
  );
};
