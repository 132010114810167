import { Navigate } from 'react-router-dom';
import { Profile } from '../models/authModel';

type Props = {
  // component: FC<{ profile: Profile; logout?: () => void }>;
  profile?: Profile | null;
  // logout?: () => void;
};

export const PrivateRoute: React.FC<React.PropsWithChildren<Props>> = ({ profile, children }) => {
  if (!profile) {
    <Navigate
      to={{
        pathname: '/login',
      }}
      replace
    />;
  }
  return <>{children}</>;
};
