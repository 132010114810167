import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { Button, Tag, Tooltip } from 'antd';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, StopOutlined } from '@ant-design/icons';

interface DataType {
  key: React.Key;
  name: string;
}

export const useRoleColumns = ({
  setIsUserActive,
  setId,
  handleOpenModal,
  setSelectedData,
  setIsEditOpen,
  setOpenDeleteModal,
}: any): ColumnsType<DataType> => {
  const navigate = useNavigate();
  return [
    {
      title: 'Role',
      width: 225,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Company',
      width: 150,
      render: (value: any) => value?.company?.name_val,
    },
    {
      title: 'users',
      width: 225,
      render: (value: any) => (
        <Button type="link" onClick={() => navigate(`/permissions/users?page=1&limit=10&role=${value.id}`)}>
          {value.users_count}
        </Button>
      ),
    },
    {
      title: 'status',
      width: 225,
      render: (value: any) => (value?.is_active ? <Tag color="green">Active</Tag> : <Tag color="red">Not active</Tag>),
    },
    {
      title: 'Action',
      key: 'action',
      render: (value: any) => (
        <>
          <Tooltip placement="top" title="Edit">
            <Button
              type="text"
              onClick={() => {
                setId(value?.id);
                // history.push(`/permissions/roles/${value.id}/edit`);
                setIsEditOpen(true);
              }}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
          {value.is_active ? (
            <Tooltip placement="top" title="Block role">
              <Button
                type="text"
                onClick={() => {
                  setIsUserActive(false);
                  setId(value?.id);
                  handleOpenModal();
                }}
              >
                <StopOutlined />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip placement="top" title="Unblock role">
              <Button
                type="text"
                onClick={() => {
                  setIsUserActive(true);
                  setId(value?.id);
                  handleOpenModal();
                }}
              >
                <CheckCircleOutlined />
              </Button>
            </Tooltip>
          )}
          <Tooltip placement="top" title="Delete">
            <Button
              type="text"
              onClick={() => {
                setSelectedData(value);
                setOpenDeleteModal(true);
              }}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];
};
