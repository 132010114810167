import { ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, MenuProps } from 'antd';
import {
  ApartmentOutlined,
  AppstoreAddOutlined,
  BankOutlined,
  BarcodeOutlined,
  FileProtectOutlined,
  FlagOutlined,
  SolutionOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

import { Profile } from '../../models';
import { NavHeader as Header } from '../header/Header';
import './protectedLayout.css';

const { Content, Sider } = Layout;

type Props = {
  children: ReactElement[] | ReactElement;
  user: Profile;
  logout: () => void;
};

export const ProtectedLayout = ({ children, user, logout }: Props) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState<string>('country');

  const items: MenuProps['items'] = [
    {
      label: (
        <Link to="/countries">
          <FlagOutlined />
          <span className="nav-text">Countries</span>
        </Link>
      ),
      key: '/countries',
    },
    {
      label: (
        <Link to="/categories">
          <AppstoreAddOutlined />
          <span className="nav-text">Categories</span>
        </Link>
      ),
      key: '/categories',
    },
    {
      label: (
        <Link to="/companies">
          <BankOutlined />
          <span className="nav-text">Companies</span>
        </Link>
      ),
      key: '/companies',
    },
    {
      label: (
        <Link to="/local-companies">
          <ApartmentOutlined />
          <span className="nav-text">Local companies</span>
        </Link>
      ),
      key: '/local-companies',
    },
    {
      label: (
        <Link to="/brands">
          <FileProtectOutlined />
          <span className="nav-text">Brands</span>
        </Link>
      ),
      key: '/brands',
    },
    {
      label: (
        <Link to="/products">
          <BarcodeOutlined />
          <span className="nav-text">Products</span>
        </Link>
      ),
      key: '/products',
    },
    {
      label: (
        <Link to="/permissions/users">
          <UsergroupAddOutlined />
          <span className="nav-text">Users</span>
        </Link>
      ),
      key: '/permissions/users',
    },
    {
      label: (
        <Link to="/permissions/roles">
          <SolutionOutlined />
          <span className="nav-text">Roles</span>
        </Link>
      ),
      key: '/permissions/roles',
    },
  ];

  useEffect(() => {
    setCurrent(location.pathname);
    return () => {
      setCurrent('country');
    };
  }, [location.pathname]);

  return (
    <Layout
      className="layout"
      style={{ display: 'flex', flexDirection: 'column', maxWidth: '100vw', overflow: 'hidden' }}
    >
      <Header user={user} logout={logout} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          theme="light"
          style={{
            overflow: 'auto',
            height: '100vh',
            left: 0,
            top: 0,
            bottom: 0,
            borderRight: '1px solid #f6f6f6',
          }}
        >
          <div className="demo-logo-vertical" />
          <Menu
            theme="light"
            mode="inline"
            items={items}
            selectedKeys={[current]}
            style={{ display: 'flex', flexDirection: 'column' }}
          />
        </Sider>

        <Content
          style={{
            backgroundColor: '#EEEEEE',
            height: '95vh',
            overflowY: 'auto',
          }}
        >
          {children}
        </Content>
      </div>
    </Layout>
  );
};
